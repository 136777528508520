import axios from 'axios'

export default {
  bankDetails(fiID) {
    return axios
      .get(`/api/banks/${fiID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
