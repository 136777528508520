<template>
  <div></div>
</template>

<script charset="utf-8">
// global
import { mapActions, mapMutations } from 'vuex'
// api
import bankAPI from '@/api/finapps/bank'
import reportAPI from '@/api/finapps/reports'
import sessionAPI from '@/api/finapps/session'
import peerGroupAPI from '@/api/finapps/peer_group'

export default {
  name: 'PageRedirect',
  created() {
    this.prepareAndRedirect()
  },
  data() {
    return {
      fiID: null,
      ssrID: null,
      reportID: null,
      peerGroupID: null,
      selectedInstitution: null,
      selectedSelfServeReport: null,
      reportCategory: 'finavigator',
      breakPointProgress: 0,
      reportProgress: 0
    }
  },
  computed: {
    getCategory() {
      return this.reportCategory === 'fin_reports'
        ? 'finavigator'
        : this.reportCategory
    },
    invalidRedirects() {
      return [
        'token_invalid',
        'token_expired',
        'invalid_fi',
        'new_user',
        'user_group_invalid',
        'user_not_confirmed',
        'email_verify',
        'purchase_fi',
        'fi_not_purchased',
        'permission_denied'
      ].includes(this.$route.query.validity)
    }
  },
  methods: {
    ...mapActions('Authentication', ['logOut']),
    ...mapActions('ThirdPartyServices', ['setUserDataToChameleon']),
    ...mapMutations(['setFinancialInstitutionBoolean']),
    ...mapMutations('Profile', ['setInstitution', 'setReportId']),
    ...mapMutations('Authentication', ['setUserData', 'setIsDirectLogin']),
    ...mapMutations('Ability', [
      'setUserRole',
      'setUserAbility',
      'setEnabledModules',
      'setEnabledPaths',
      'setEnterprisePermissions',
      'setActiveClientPricingAvailable'
    ]),
    ...mapMutations({
      setReportToken: 'setReportToken',
      updateReportId: 'updateReportId',
      setReporterPeerGroup: 'setPeerGroup',
      setReportProgress: 'setReportProgress',
      updateMetricArgs: 'update_metric_args',
      updateReporterPeerGroups: 'peer_groups',
      setReporterInstitution: 'setInstitution',
      setBreakPointProgress: 'setBreakPointProgress',
      setReportView: 'setReportView'
    }),
    ...mapMutations('CustomPeerGroup', ['resetCustomPeerGroupDetails']),
    prepareAndRedirect() {
      if (this.invalidRedirects) {
        switch (this.$route.query.validity) {
          case 'token_invalid':
            this.$toasted.global.invalid(
              'Invalid client token. Please contact admin.'
            )
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'token_expired':
            this.$toasted.global.invalid(
              'Client token expired. Please contact admin.'
            )
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'invalid_fi':
            this.$toasted.global.invalid(
              'FI does not exist. Please contact admin.'
            )
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'new_user':
            this.$toasted.global.invalid(
              'User does not exist. Please contact admin.'
            )
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'user_group_invalid':
            this.$toasted.global.invalid(
              'User does not exist. Please contact admin.'
            )
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'user_not_confirmed':
            this.$toasted.global.invalid('Your email verification is pending.')
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'email_verify':
            this.$toasted.global.action_success(
              'Verification code sent to your email. Please verify to continue'
            )
            this.$router.push(`/verify_email?token=${this.$route.query.token}`)
            break
          case 'purchase_fi':
            this.$toasted.global.invalid(
              'Access to this report has not been purchased. Please order a new institution.'
            )
            this.fetchSessionUserData(
              this.$route.query.target === 'profile'
                ? '/analytics/fin_profile/select_institution'
                : '/analytics/reporter/select_institution'
            )
            break
          case 'fi_not_purchased':
            this.$toasted.global.invalid(
              'FI not available for client. Please contact admin.'
            )
            this.$router.push({ name: 'SignIn', params: { silent: true } })
            break
          case 'permission_denied':
            this.$toasted.global.invalid(
              'Page access restricted. Please contact admin.'
            )
            this.fetchSessionUserData('/dashboard')
            break
        }
      } else {
        switch (this.$route.query.target) {
          case 'profile':
            this.profileRedirect()
            break
          case 'reporter':
            this.reporterRedirect()
            break
        }
      }
    },
    profileRedirect() {
      this.fiID = parseInt(this.$route.query.fi_id)

      switch (this.$route.query.validity) {
        case 'zero_purchase':
          this.$toasted.global.action_success('Selected FI newly ordered.')
          this.loadProfileDetailsAndRedirect({
            trackFIOrder: true,
            type: 'fi_order'
          })
          break
        case 'valid':
          this.loadProfileDetailsAndRedirect()
          break
        default:
          this.$toasted.global.invalid(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
          )
          this.$router.push({ name: 'SignIn', params: { silent: true } })
          break
      }
    },
    reporterRedirect() {
      this.fiID = parseInt(this.$route.query.fi_id)
      this.ssrID = parseInt(this.$route.query.ssr_id)
      this.reportID = parseInt(this.$route.query.report_id)
      this.peerGroupID = parseInt(this.$route.query.peer_group_id)
      this.reportCategory = this.$route.query.report_category
      switch (this.$route.query.validity) {
        case 'zero_purchase':
          this.$toasted.global.action_success('Selected FI newly ordered.')
          this.loadReporterDetailsAndRedirect({
            trackFIOrder: true,
            type: 'fi_order'
          })
          break
        case 'valid':
          this.loadReporterDetailsAndRedirect()
          break
        default:
          this.$toasted.global.invalid(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.'
          )
          this.$router.push({ name: 'SignIn', params: { silent: true } })
          break
      }
    },
    loadProfileDetailsAndRedirect(options = {}) {
      this.fetchBankDetails().then(() => {
        this.setInstitution({
          id: this.selectedInstitution.id,
          name: this.selectedInstitution.name,
          fiType: this.selectedInstitution.fi_type
        })
        this.setReportId(null)
        this.trackFIOrder(options)
        this.fetchSessionUserData(
          '/analytics/fin_profile/custom_profile',
          options
        )
      })
    },
    loadReporterDetailsAndRedirect(options = {}) {
      Promise.all([
        this.fetchBankDetails(),
        this.fetchPeerGroups(),
        this.fetchReportDetails()
      ]).then(() => {
        this.setReporterInstitution(this.selectedInstitution)
        this.resetCustomPeerGroupDetails()
        this.setReporterPeerGroup(this.peerGroupID)
        this.updateMetricArgs({
          reportId: this.reportID,
          reportTypeId: this.ssrID,
          reportType: this.getCategory,
          technographicReport: false
        })
        this.setReportView('multi')
        this.trackFIOrder(options)
        this.setBreakPointProgress(this.breakPointProgress)
        this.setReportProgress(this.reportProgress)
        this.fetchSessionUserData(
          `/analytics/reporter/metrics?id=${this.reportID}&report_id=${this.ssrID}&report_type=${this.getCategory}`,
          { trackReportView: true, type: 'FIN-R', reportID: this.reportID }
        )
      })
    },
    fetchBankDetails() {
      return bankAPI.bankDetails(this.fiID).then(res => {
        this.selectedInstitution = res.financial_institution
      })
    },
    fetchPeerGroups() {
      return peerGroupAPI.fiPeerGroups(this.fiID).then(res => {
        let defaultPeerGroups = res.peer_groups.filter(
          pg => pg.creator_id === null
        )
        let userPeerGroups = res.peer_groups.filter(
          pg => pg.creator_id !== null
        )
        let sortedPeerGroups = defaultPeerGroups.concat(userPeerGroups)
        this.updateReporterPeerGroups(sortedPeerGroups)
      })
    },
    fetchReportDetails() {
      return reportAPI.reportDetails(this.fiID, this.peerGroupID).then(res => {
        this.updateReportId(res.reports.report_id)
        this.setReportToken(res.reports.report_token)
        this.breakPointProgress = res.reports.breakpoint_progress
        this.reportProgress = res.reports.report_progress
      })
    },
    fetchSessionUserData(redirectPath, options = {}) {
      sessionAPI.userDetails().then(res => {
        this.setIsDirectLogin(true)
        this.$ahoy.track('login - Direct URL', { user_id: res.id })
        this.setUserData(res)
        this.setFinancialInstitutionBoolean()
        this.setUserRole(res.role.name)
        this.setUserAbility()
        this.setEnabledModules(res.ui_modules)
        this.setEnabledPaths(res.ui_modules)
        this.setActiveClientPricingAvailable(res.active_client_pricing)
        this.setEnterprisePermissions(res.enterprise_permissions)
        this.setUserDataToChameleon()
        this.trackReportView(options)
        this.$router.push(redirectPath)
      })
    },
    trackFIOrder(options) {
      if (options.trackFIOrder) {
        this.$ahoy.track('fi_order', {
          fi_ids: [this.selectedInstitution.id]
        })
      }
    },
    trackReportView(options) {
      if (options.trackReportView) {
        if (options.type === 'FIN-R') {
          this.trackReporterView(options.reportID)
        }
      }
    },
    trackReporterView(reportID) {
      this.$ahoy.track('report_view - Direct URL', {
        report_type: 'FIN-R',
        report_id: reportID,
        fi_id: this.selectedInstitution.id
      })
    }
  }
}
</script>
