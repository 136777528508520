import axios from 'axios'

export default {
  reportDetails(fiID, peerGroupID) {
    return axios
      .post(
        'api/reports',
        {
          bank_id: fiID,
          peer_group_id: peerGroupID
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  }
}
