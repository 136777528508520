import axios from 'axios'

export default {
  userDetails() {
    return axios
      .get('/api/sessions/user_details', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
